import mainAxios from "../Axios/Axios";
import store from "../redux/createStore";

async function fetchCountries() {
  try {
    const countryReq = await mainAxios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/v3/Locations/Countries`
    );
    return countryReq.data;
  } catch (err) {
    console.log(err);
    alert("Error Occured while fetching the countries ");
    return [];
  }
}

async function fetchStates(countryId) {
  try {
    if (countryId) {
      const stateReq = await mainAxios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/v3/Locations/Countries/${countryId}/States`
      );
      return stateReq.data;
    }

    return [];
  } catch (err) {
    console.log(err);
    alert("Error Occured while fetching the state ");
    return [];
  }
}

async function fetchAvailability(vehicletypeid, clientid, locationid) {
  try {
    const state = store.getState();

    vehicletypeid = vehicletypeid || state.form.selectedVehicle.VehicleTypeId;
    clientid = clientid || state.UISettings.clientId;
    locationid = locationid || state.form.location;

    const availability = await mainAxios.get(
      `/api/v3/vehicleTypes/${vehicletypeid}/availability?ClientId=${clientid}&LocationId=${locationid}`
    );

    return availability.data;
  } catch (err) {
    console.log(err);
  }
}

async function fetchInsuranceCompanies(clientId) {
  try {
    clientId = clientId || store.getState().UISettings.clientId;

    const insuranceReq = await mainAxios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v3/InsuranceCompanies/?clientid=${clientId}`
    );

    return insuranceReq.data;
  } catch (err) {
    console.log(err);
  }
}

const getCenPosToken = async (clientId, locationCode) => {
  clientId = clientId || store.getState().UISettings.clientId;
  locationCode = locationCode || store.getState().form.location;

  const response = await mainAxios.get(
    `${process.env.REACT_APP_RESERVATION_API_BASE_URL}api/paymentgateway/cenpos?clientId=${clientId}&locationCode=${locationCode}`
  );

  return response;
};

export {
  fetchCountries,
  fetchStates,
  fetchAvailability,
  fetchInsuranceCompanies,
  getCenPosToken,
};
